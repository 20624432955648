import Footer from "../Footer/Footer";
import Header from "../NavBar/Header";
import { Helmet } from "react-helmet";
import "./about.scss";
import TeamMember from "./TeamMember";
import amritha from "./teams/amritha.jpg";
import allen from "./teams/allen.jpg";
import sapna from "./teams/sapna.jpg";
import shameer from "./teams/shameer.jpg";
import vyshnav from "./teams/vyshnav.jpeg";
import rahul from "./teams/rahul.JPG";
import surya from "./teams/surya.jpeg";
import jebin from "./teams/jebin.jpeg";
import Sayujya from "./teams/Sayujya.jpeg";
import sajan from "./teams/sajan.jpeg";
import hari from "./teams/hari.jpg";
import varun from "./teams/varun.jpg";
import jaidan from "./teams/jaidan.jpg";
import aswin from "./teams/aswin.jpg";
import yasim from "./teams/yasim.jpg"; 
import linette from "./teams/linette.jpeg";
import logo from "../../assets/logo.png";
const About = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>About US | Nerchapetti</title>
        <meta property="og:site_name" content="Nerchapetti" />
        <meta property="og:title" content="Team of Nerchapetti" />
        <meta property="og:url" content="https://nerchapetti.com" />
        <meta property="og:image" itemprop="image" content={logo} />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="About the team of Nerchapetti"
        />
      </Helmet>
      <Header />
      <div className="head">
        <h2>About</h2>
        <hr />
      </div>
      <div className="content1">
        <div>
          <h2>Mission</h2>
          <div className="full-paragraph">
            <p>
              Our mission is to support Christian parishes and institutions in
              accepting offerings and other related payments. We also support
              fund-raising initiatives using the power of digital marketing.
            </p>
          </div>
        </div>
        <div>
          <h2>Vision</h2>
          <div className="full-paragraph">
            <p>
              Be an instrument to God's Will, by ensuring that the necessary
              resources are made available to the right people at the right
              time.
            </p>
          </div>
        </div>
        <div>
          <h2>Values</h2>
          <div className="full-paragraph">
            <p>
              <ul>
                <li>We lead with optimism</li>
                <li>We have faith in God</li>
                <li>We serve and let others serve for the humankind</li>
              </ul>
            </p>
          </div>
        </div>
      </div>
      <div className="main-container-about">
        <div className="team">
          <h1>Our Team</h1>
          <hr />
          <div className="team-members">
            <TeamMember
              name="Amritha M L"
              about="DevOps-Web"
              img={amritha}
              l="amritha-ml-9401b014b"
            />
            <TeamMember
              name="Sayujya surjit"
              about="Content-Writer"
              img={Sayujya}
              l="sayujya-surjit-49b6061b2"
            />
            <TeamMember
              name="Linette Joseph"
              about="Content-Writer"
              img={linette}
              l="linette-joseph-59939b200"
            />
            <TeamMember
              name="Harikrishnan V"
              about="DevOps-Web"
              img={hari}
              l="harikrishnan-v-5746b8212"
            />
            <TeamMember
              name="Varun Krishna"
              about="DevOps-Web"
              img={varun}
              l="varun-krishna-122534212"
            />
            <TeamMember
              name="Jaidan Sleeba"
              about="DevOps-Web"
              img={jaidan}
              l="jaidan22"
            />
            <TeamMember
              name="Aswin Jayaji"
              about="DevOps-Web"
              img={aswin}
              l="aswin-jayaji"
            />
            <TeamMember
              name="Mohammed Yasim"
              about="DevOps-Web"
              img={yasim}
              l="mohammed-yasim-edakkunnam-2243651a7"
            />
          </div>
          <h1 className="position">Alumni</h1>
          <hr />
          <div className="team-members">
            <TeamMember
              name="Rahul T"
              about="DevOps-Web"
              img={rahul}
              l="rahulmanojcet"
            />
            <TeamMember
              name="Allen Vengal"
              about="Chief Operating Officer"
              img={allen}
              l="allen-vengal-79bb081a"
            />
            <TeamMember
              name="Sajan Joseph "
              about="Operations"
              img={sajan}
              l="sajan-joseph-35331110a"
            />
            <TeamMember
              name="Surya V R"
              about="DevOps-Web"
              img={surya}
              l="surya-vr-8a7b501a7"
            />
            <TeamMember
              name="Vaishnav P"
              about="DevOps-Web"
              img={vyshnav}
              l="vrv-vyshnav"
            />
            <TeamMember
              name="Shameer M R"
              about="DevOps-Web"
              img={shameer}
              l="shameer-m-r-5916771b6"
            />
            <TeamMember
              name="sapna"
              about="DevOps-Web"
              img={sapna}
              l="sapna2001"
            />
            <TeamMember
              name="Jebin Jose "
              about="Operations"
              img={jebin}
              l="jebin-jose-6b30491b8"
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default About;
