import emailjs from "emailjs-com";
import "./Contact.scss";
import Header from "../NavBar/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import Footer from "../Footer/Footer";
import { useState } from "react";
import Error from "../Error/Error";
import Success from "../Success/Success";

function ContactUs() {
  const [isLoading, setisLoading] = useState(false);
  const [success, setsuccess] = useState(false);
  const [error, seterror] = useState(false);

  function sendEmail(e) {
    e.preventDefault();
    setisLoading(true);

    emailjs
      .sendForm(
        "service_y4gm0ph",
        "template_kk3yewe",
        e.target,
        "user_1sM8WqLiODpdRvbwWVH1a"
      )
      .then(
        (result) => {
          console.log(result.text);
          if (result.text === "OK") {
            setsuccess(true);
            setisLoading(false);
            window.scrollTo(0, 0);
          } else {
            seterror(true);
            setisLoading(false);
            window.scrollTo(0, 0);
            console.log(result);
          }
        },
        (error) => {
          console.log(error.text);
          seterror(true);
          setisLoading(false);
          window.scrollTo(0, 0);
        }
      );
  }

  if (isLoading) {
    return (
      <>
        <Header />
        <div className="isLoading"></div>
        <Footer />
      </>
    );
  }

  return (
    <>
      <Header />
      {error ? <Error error={error} /> : ""}
      {success ? (
        <Success success={success} message="message sent successfully" />
      ) : (
        ""
      )}
      <h1 className="heading-contact">CONTACT US</h1>
      <div className="contactdata">
        <div className="contact-container">
          <div className="contactdatas">
            <p>
              <a href="tel:+919747121112">
                <FontAwesomeIcon icon={faPhoneAlt} className="icons" />
                &emsp; 9747121112
              </a>
              
              <a href="mailto:contact@nerchapetti.com">
                <FontAwesomeIcon icon={faEnvelope} className="icons" />
                &emsp; Contact@nerchapetti.com
              </a>
            </p>
            <p>
              John Zakariah Technologies, <br />
              Veera Marthanda Varma Road, <br />
              Podiyadi P. O. <br />
              Thiruvalla, Kerala <br />
              689 110 (India) <br /> <br />
              Time: Mon - Fri (9:00 - 16:00)
            </p>
          </div>
        </div>

        <form className="contact-form" onSubmit={sendEmail}>
          <div className="allcontain">
            <div className="selects">
              <div className="form-data">
                <select name="query" id="">
                  <option value="s">Select a Query Type</option>
                  <option value="general">General Query</option>
                  <option value="partnership">Partnership Query</option>
                  <option value="report">Report</option>
                </select>
              </div>
              <div className="form-data">
                <input
                  className="inputbox"
                  name="contact_number"
                  placeholder="Phone Number"
                  required
                />
                <label>Phone No</label>
              </div>
              <div className="form-data">
                <input
                  type="text"
                  className="inputbox"
                  name="user_name"
                  required
                  placeholder="Name"
                />
                <label>Name</label>
              </div>

              <div className="form-data">
                <input
                  className="inputbox"
                  type="email"
                  name="user_email"
                  required
                  placeholder="Email"
                />
                <label>Email</label>
              </div>
            </div>

            <div className="form-data-message">
              <textarea rows="8" name="message" placeholder="Message"/>
            </div>

            <div className="form-data-submit">
              <input type="submit" value="Send" />
            </div>
          </div>
        </form>
      </div>
      <Footer />
    </>
  );
}

export default ContactUs;
